import React, { MutableRefObject, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  SubmitButton
} from 'components';
import { statusMessageList } from 'helpers';
import { useUpdateShipmentMutation } from 'operations/mutations';
import { Box, Input, InputLabel } from '@material-ui/core';
import { shipmentMessageSplitter } from 'constant';
import { budgetCountriesEU } from 'helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1)
    },
    formElement: {
      textAlign: 'start',
      maxWidth: '70%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    formContainer: {
      textAlign: 'center'
    },
    formHelper: {
      color: 'red'
    },
    success: {
      marginTop: '20px',
      marginBottom: '60px',
      textAlign: 'center'
    },
    message: {
      fontWeight: 500
    }
  })
);

type Props = {
  handleClose: () => void;
  open: boolean;
  reset: boolean;
  setReset: (reset: boolean) => void;
  trackingId: string;
  shipmentId: string;
  shipmentTableRef: MutableRefObject<any>;
  shipmentStatuses: Array<string>;
  statusDictionary: (data: string) => string;
  receiverCountryCode?: string;
  shipmentPricingTier?: string;
  tracks?: Array<{
    createdDate: string;
    message: string;
    itemLocation: string;
    status: string;
    updatedDate: string;
    transshipmentPoint: string;
  }>;
};

const ChangeStatusDialog = ({
  handleClose,
  open,
  reset,
  setReset,
  trackingId,
  shipmentId,
  shipmentTableRef,
  shipmentStatuses,
  statusDictionary,
  receiverCountryCode,
  shipmentPricingTier,
  tracks = []
}: Props) => {
  const classes = useStyles();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [messageStatusList, setMessageStatusList] = useState<Array<string>>([]);
  const [selectedStatus, setSelecltedStatus] = useState<string>('');
  const updateShipmentMutation = useUpdateShipmentMutation();

  const validationSchema = yup.object().shape({
    status: yup.string().required(`Please select a shipment status`),
    statusMessage: yup.string()
  });

  const {
    errors,
    values,
    touched,
    resetForm,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: '',
      statusMessage: ''
    },
    validationSchema,
    onSubmit: async values => {
      setLoading(true);
      const data = {
        shipmentStatus: values.status
      };

      let statusMessage = values.statusMessage || undefined;

      if (statusMessage && existingStatus) {
        statusMessage = `${existingStatus.message}${shipmentMessageSplitter}${statusMessage}`;
      }

      await updateShipmentMutation(shipmentId, data, statusMessage)
        .then(response => {
          // refresh table data
          if (shipmentTableRef) {
            shipmentTableRef.current &&
              shipmentTableRef.current.onQueryChange();
          }
          setReset(false);
          setSuccess(true);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setSuccess(false);
          setLoading(false);
        });
    }
  });

  const getMessageStatusList = (
    status: string,
    receiverCountryCode?: string
  ) => {
    setFieldValue('statusMessage', '');
    const messageList = statusMessageList(
      status,
      receiverCountryCode,
      shipmentPricingTier
    );
    setMessageStatusList(messageList);
  };

  const existingStatus = useMemo(() => {
    if (!values.status || !tracks.length) {
      return null;
    }

    if (tracks[0].status !== values.status) {
      return null;
    }

    return tracks[0];
  }, [tracks, values.status]);

  const close = () => {
    setSuccess(false);

    resetForm();

    handleClose?.();

    setSelecltedStatus('');
  };

  const showIntransitMessageOptions = useMemo(() => {
    const isBudget =
      shipmentPricingTier === 'LastMileBudget' &&
      budgetCountriesEU.includes(receiverCountryCode || '');

    return (
      selectedStatus === 'InTransit' &&
      (isBudget || shipmentPricingTier === 'Premium')
    );
  }, [selectedStatus, receiverCountryCode, shipmentPricingTier]);

  return (
    <div>
      <Dialog
        onClose={close}
        aria-labelledby='customized-dialog-title'
        maxWidth='xs'
        fullWidth
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={close}>
          Change Status For Shipment <br /> {trackingId}
        </DialogTitle>

        {!reset && success ? (
          <div className={classes.success}>
            <img
              width='40%'
              src='/static/images/svg/checkmark.svg'
              alt='checkmark'
            />

            <Typography className={classes.message}>
              Shipment status was successfully changed to {values.status}
            </Typography>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <div className={classes.formContainer}>
                <FormControl className={classes.formElement} fullWidth>
                  <Select
                    id='status'
                    name='status'
                    value={values.status}
                    onChange={e => {
                      handleChange(e);
                      setSelecltedStatus(e.target.value as string);
                      getMessageStatusList(
                        e.target.value as string,
                        receiverCountryCode
                      );
                    }}
                    error={Boolean(touched.status && errors.status)}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value='' disabled>
                      Shipment Status
                    </MenuItem>

                    {shipmentStatuses.map(item => (
                      <MenuItem key={item} value={item}>
                        {statusDictionary(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {existingStatus && (
                  <Box mt={5}>
                    <Typography variant='h6'>Existing Messages</Typography>
                    <Box>
                      {existingStatus.message
                        .split(shipmentMessageSplitter)
                        .map((message, index) => (
                          <Typography variant='body1' key={index}>
                            - {message}
                          </Typography>
                        ))}
                    </Box>
                    {!showIntransitMessageOptions ? (
                      <FormControl
                        style={{ marginTop: '1em' }}
                        className={classes.formElement}
                        fullWidth
                      >
                        <InputLabel className='form-label' htmlFor='bonus'>
                          New Message
                        </InputLabel>

                        <Input
                          id='statusMessage'
                          type='text'
                          name='statusMessage'
                          value={values.statusMessage}
                          onChange={handleChange}
                          error={Boolean(
                            touched.statusMessage && errors.statusMessage
                          )}
                          fullWidth
                        />

                        <small>
                          {touched.statusMessage && errors.statusMessage}
                        </small>
                      </FormControl>
                    ) : null}
                  </Box>
                )}

                {(messageStatusList.length &&
                  shipmentPricingTier?.includes('Budget')) ||
                showIntransitMessageOptions ? (
                  <FormControl className={classes.formElement} fullWidth>
                    <Select
                      id='statusMessage'
                      name='statusMessage'
                      value={values.statusMessage}
                      onChange={handleChange}
                      error={Boolean(
                        touched.statusMessage && errors.statusMessage
                      )}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ paddingTop: '8px' }}
                    >
                      <MenuItem value='' disabled>
                        Status Message
                      </MenuItem>

                      {messageStatusList.map(statusMessage => (
                        <MenuItem key={statusMessage} value={statusMessage}>
                          {statusMessage}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText className={classes.formHelper}>
                      {touched.statusMessage && errors.statusMessage}
                    </FormHelperText>
                  </FormControl>
                ) : null}
              </div>
            </DialogContent>

            <DialogActions>
              <div>
                <SubmitButton
                  text='Change Status'
                  disabled={loading}
                  loading={loading}
                  width='100%'
                />
              </div>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
};

export default ChangeStatusDialog;
