export const budgetCountriesEU = [
  'ES',
  'PT',
  'IE',
  'AD',
  'GI',
  'IM',
  'NL',
  'MC',
  'CH',
  'AT',
  'LI',
  'DE',
  'FR',
  'BE',
  'LU'
];

export const statusMessageList = (
  value: string,
  receiverCountryCode?: string,
  shipmentPricingTier?: string
) => {
  const isBudget =
    shipmentPricingTier === 'LastMileBudget' &&
    budgetCountriesEU.includes(receiverCountryCode || '');

  if (value === 'DeliveryInProgress') {
    return [
      'Shipment Dispatched for Delivery',
      'Doorstep Delivery in Progress'
    ];
  }

  if (value === 'ShipmentProcessing') {
    return [
      'Shipment processing for Export at Gateway',
      'Export clearance complete',
      'Exception, please contact support'
    ];
  }

  if (value === 'InTransit' && receiverCountryCode === 'US') {
    return [
      'Shipment In Transit to London, Heathrow, UK',
      'Shipment Arrived London, Heathrow, UK',
      'Shipment In Transit to JFK, New York, USA',
      'Shipment Arrived JFK, New York, USA',
      'Customs Clearance in Progress',
      'Customs Clearance Complete'
    ];
  }

  if (value === 'InTransit' && receiverCountryCode === 'GB') {
    return [
      'Shipment In Transit to London, Heathrow, UK',
      'Shipment Arrived London, Heathrow, UK',
      'Customs Clearance in Progress',
      'Customs Clearance Complete'
    ];
  }

  if (value === 'InTransit' && receiverCountryCode === 'CA') {
    return [
      'Shipment In Transit to  JFK, New York, USA',
      'Shipment Arrived  JFK, New York, USA',
      'Shipment In Transit to Pearson International, Toronto',
      'Shipment Arrived Pearson International, Toronto',
      'Customs Clearance in Progress',
      'Customs Clearance Complete'
    ];
  }
  if (value === 'InTransit' && isBudget) {
    return [
      'Rider has picked up and package is out for delivery',
      'In transit to the London, Heathrow, UK',
      'Customs clearance in progress',
      'Departed London, Heathrow, UK',
      'Shipment in Transit to (Spain, Portugal, Ireland, Andorra, Gibraltar, Isle of Man, Netherlands,  Monaco, Switzerland, Austria, Liechtenstein, Germany, France, Belgium, Luxembourg )',
      'Shipment Arrived (Spain, Portugal, Ireland, Andorra, Gibraltar, Isle of Man, Netherlands,  Monaco, Switzerland, Austria, Liechtenstein, Germany, France, Belgium, Luxembourg )',
      'Delivery In Progress'
    ];
  }
  if (value === 'InTransit' && shipmentPricingTier === 'Premium') {
    return [
      'Rider has picked up and package is out for delivery',
      'In transit to Murtala Muhammed Airport, Lagos',
      'In transit to Nnamdi Azikwe, Airport, Abuja',
      'Departed from Murtala Muhammed Airport, Lagos',
      'Departed from Nnamdi Azikwe, Airport, Abuja',
      'Delivery in Progress'
    ];
  }

  return [];
};
