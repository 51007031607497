export const shipmentRateModeDictionary = (value: string) => {
  const shipmentModes: any = {
    express: 'Express',
    budget: 'Budget',
    standard: 'Standard',
    premium: 'Premium',
    plus: 'Premium',
    fedex: 'FedEx',
    saverpriority: 'SaverPriority',
    upseconomy: 'UPSEconomy',
    upsexpress: 'UPSExpress',
    upsexpedited: 'UPSExpedited',
    lastmilebudget: 'LastMileBudget',
    regular: 'Regular',
    gig: 'GIG',
    seaexport: 'SeaExport',
    dellyman: 'Dellyman',
    errandlr: 'Errandlr',
    fez: 'Fez',
    glovo: 'Glovo',
    aramex: 'Aramex',
    chowdeck: 'Chowdeck'
  };

  let selectedMode = '';

  Object.keys(shipmentModes).map(mode => {
    if (value.toLowerCase().includes(mode)) {
      selectedMode = shipmentModes[mode];
    }

    return '';
  });
  return selectedMode.length ? selectedMode : 'Mode Unavailable';
};
